import React, { useEffect, useState } from "react";
import {
  Widget,
  addResponseMessage,
  addUserMessage,
  renderCustomComponent,
  toggleWidget
} from "react-chat-widget";
import style from "./app.module.css";

// import "react-chat-widget/lib/styles.css";
import './chat.module.css';

import WebSocketManager from "./manager/WebSocketManager";
import { getUsefulData, messageProcessor } from "./utils/messageProcessor";
import { getUUID } from "./utils/uuid";
import Image from "./components/Image";
import DocumentCustom from "./components/DocumentCustom";
import MultiChoice from "./components/MultiChoice";
import Typing from './components/Typing.jsx'
import FullScreenIframe from './components/FullScreenIframe'

const webSocketManager = new WebSocketManager('wss://acai-api.asi-analytics.com/webchat/socket');
const getCorrectComponent = (message) => {
  // console.log(message);
  const type = message?.content?.type || message?.type || "";
  switch (type) {
    case "image":
      return (
        <div>
          { message?.content?.caption && <span>{message?.content?.caption}</span>}
          <Image src={message?.content?.link || ""} alt="Image not loaded." />
        </div>
      );
    // case 'video':
    //   return { type: 'component', }
    case "document":
      return (
        <DocumentCustom
          docType={message?.content?.mimeType || ""}
          docLink={message?.content?.link || ""}
          fileName={message?.content?.fileName || ""}
          fileSize={message?.content?.fileSize || 0}
        />
      );
    case "location":
      return (
       <div>{message?.content?.address}</div> 
      );
    case "multiChoice":
      if (message?.content?.options) {
        message.content.options = message.content.options.map(
          (option, index) => {
            return {
              ...option,
              onClick: () => {
                disableAllOptions(message.content.options);
                // console.log(index, "index selected", option);
                addUserMessage(option.title);
                const newMessage = messageProcessor({
                  type: "text",
                  content: {
                    text: option.title,
                  },
                });
                // socket.emit("message", newMessage);
                webSocketManager.send({
                  "id": getUUID(),
                  "type": "text",
                  "content": {
                      "text": option.title
                  }
                })
              },
            };
          }
        );
      }
      return (
        <MultiChoice
          text={message?.content?.text || ""}
          options={message?.content?.options || null}
        />
      );
    // case 'audio':
    //   return <AudioTemp message={message} />
    // case 'contacts':
    //   return <Contact message={message} />;

    default:
      break;
  }
  return "";
};

function MessageRenderer({ message }) {
  // console.log({ message })
  // if (message.from === 'customer') {
  //   return (
  //     <div className={`showMessageOnRight`}>{getCorrectComponent(message)}</div>
  //   )
  // } else {
  const data = getCorrectComponent(message);
  if( data ){
    return (
      <div className={`${style["left-message"]}`}>
        {data}
      </div>
    );
  } else {
    return null;
  }
  // }
}

function removeTypingNode() {
  try {
    const typingNode = document.getElementById('typing');
    if (typingNode) {
      // typingNode.parentElement?.remove();
      typingNode.parentElement && typingNode.parentElement.remove();
    }
  } catch (error) {
    console.error(error);
  }
}

const App = () => {
  function receiveMessage(message) {
    removeTypingNode();
    const type = message?.type || "";
    // console.log("Type -> ", type);
    if (type === "text") {
      addResponseMessage(message.content.text);
    } else {
      renderCustomComponent(MessageRenderer, { message }, true);
    }
  }

  function socketEventHandler(receivedEvent) {
    // console.log({ receivedEvent });
    if (
      receivedEvent?.payloadType === "event" &&
      receivedEvent.event?.type === "typing" &&
      receivedEvent.event.status === true
    ) {
      // removeTypingNode();
      renderCustomComponent(Typing, {}, true);
    } else if (
      receivedEvent?.payloadType === "event" &&
      receivedEvent.event?.type === "typing" &&
      receivedEvent.event.status === false
    ) {
      removeTypingNode();
    }
  }

  useEffect(() => {
    webSocketManager.addListener("message",receiveMessage)
    // socket.on("event", socketEventHandler);
    toggleWidget();

    return () => {
      webSocketManager.removeListener("message",receiveMessage)
    };
  }, []);

  const handleNewUserMessage = (newMessage) => {
    webSocketManager.send({
      "id": getUUID(),
      "type": "text",
      "content": {
          "text": newMessage
      }
    })
    // console.log(`New message incoming! ${newMessage}`);
  };

  return (
    <div className="App">
      {/* <FullScreenIframe/> */}
      <Widget
        handleNewUserMessage={handleNewUserMessage}
        title="ACAI"
        subtitle="Navigating the path to perfect coverage"
        launcher={false}
        emojis={true}
        showBadge={true}
        fullScreenMode={true}
        showCloseButton={false}
      />
    </div>
  );
};

export default App;

function disableAllOptions(options) {
  for (const option of options) {
    option.disabled = true;
  }
}
